$green-light: #EBFCCD;
$green-medium: #d1e269;
$green-dark: #6B8500;
$brown: #493e07;
$dark: #171810;
$dark-text: #262626;

.bg-green-light {
 background-color: $green-light !important;
}
.bg-green-medium {
 background-color: $green-medium !important;
}
.bg-green-dark {
 background-color: $green-dark !important;
}
.bg-brown {
 background-color: $brown !important;
}

.text-green-light {
  color: $green-light !important;
}
.text-green-medium {
  color: $green-medium !important;
}
.text-green-dark {
  color: $green-dark !important;
}
.text-brown {
  color: $brown !important;
}